const eng = {
  mainPage: {
    mainSec: {
      buttonReserve: "RESERVE",
    },
    aboutHotelSec: {
      title: "About the Hotel",
      paragraph:
        "Our cozy mini-hotel is part of a hotel and residential complex. The advantage of the hotel is its unique location in the heart of historic Kyiv - between Kontraktova Square (Podil) and Sofiyivska Square (Verkhnyi Misto). We care about safety and sincerely care about the comfort of our guests. We offer accommodation in apartments of three different categories. ",
      buttonText: "LEARN MORE",
    },
    typeRoomSec: {
      buttonText: "VIEW ROOM",
      guest: "guests",
      bed: "bed",
      economy: {
        title: "Economy Room",
        description:
          "A set of all the essential amenities for your stay.",
      },
      comfort: {
        title: "Comfort",
        description: "A cozy room with all modern conveniences.",
      },
      lux: {
        title: "Lux",
        description: "A spacious and comfortable room for relaxation.",
      },
    },
    prosSec: {
      locationText: {
        title: "Location",
        description: "Right in the center",
      },
      kitchenZoneText: {
        title: "Kitchen Zone",
        description: "All rooms have a kitchen zone",
      },
      washingText: {
        title: "Parking",
        description: "Private Parking*",
        notice: "*Extra charge",
      },
    },
  },
  header: {
    nav: {
      mainText: "Main",
      roomsText: "Rooms",
      galleryText: "Gallery",
      rulesText: "Rules",
      contactsText: "Contacts",
    },
    buttonText: "RESERVE",
  },
  footer: {
    adress: "Harmony, Kyiv, Vozdvyzhens'ka St, 60В",
    socialMedia: "Contact us",
  },
  roomsPage: {
    title: "Rooms",
    miniTitleText: "Room",
    priceSec: {
      fromText: "from",
      detail: "UAH/night",
    },
    detailsSec: {
      guestNumText: "Guests",
      roomNumText: "Room",
      areaText: "m2",
      bedText: "Bed",
      bedsText: "Beds",
      tvText: "TV",
      waterText: "Drinking Water",
    },
    economy: {
      title: "Economy",
      description:
        "NO WINDOW, double bed, microwave oven, electric kettle, mini fridge, tableware, fan, ceramic heater, TV (cable television), safe, towels, shower gel, shampoo, hairdryer",
    },
    comfort: {
      title: "Comfort",
      description:
        "Balcony, double bed, microwave oven, electric kettle, mini fridge, tableware, fan, ceramic heater, TV (cable television), safe, towels, shower gel, shampoo, hairdryer",
    },
    lux: {
      title: "Lux",
      description:
        "Balcony, king-size double bed, sofa bed, microwave oven, electric kettle, mini fridge, tableware, fan, ceramic heater, TV (cable television), safe, towels, shower gel, shampoo, hairdryer",
    },
  },
  galleryPage: {
    title: "Gallery",
    closeButton: "Close",
  },
  contactsPage: {
    title: "Contacts",
    contactSec: {
      adress: {
        title: "Adress",
        adressText: "Harmony, Kyiv, Vozdvyzhens'ka St, 60В",
      },
      phone: {
        title: "Phone",
      },
      email: {
        title: "Email",
      },
    },
  },
  rulesPage: {
    title: "Rules",
    rulesText: {
      line1: "1. Check-in and check-out times",
      line1_1: "• Check-in time: from 13:00",
      line1_2: "• Check-out time: until 11:00",
      line1_3:
        "• Late check-out and early check-in are paid additionally by agreement with the administration.",

      line2: "2. Check-in",
      line2_1:
        "• Check-in is carried out in person or, by prior arrangement, with the administration in a contactless mode.",
      line2_2:
        "• A valid state identity document is required for check-in.",

      line3: "3. Payment for accommodation",
      line3_1: "• Full payment is due upon arrival.",
      line3_2:
        "• As a guarantee for stays longer than one day, a prepayment in the amount of one night's accommodation is required.",
      line3_3:
        "• Accepted forms of payment: cash, payment by credit card, bank transfer to the company's account.",
      line3_4:
        "• By prior arrangement, the hotel provides documents for business trip reporting.",
    
      line4: "4. Silence policy",
      line4_1:
        "• Quiet time from 22:00 to 08:00, please respect other guests and keep noise to a minimum.",

      line5: "5. Smoking and alcohol",
      line5_1:
        "• Smoking is not allowed on the premises. Smoking is allowed only in designated areas (balconies).",
      line5_2: "• Alcohol consumption is allowed in moderation, provided it does not disturb other guests.",
      line6: "6. Pets",
      line6_1:
        "• Pets of small breeds are allowed by prior agreement with the administration.",
      line7: "7. Order in the rooms",
      line7_1: "• Guests must maintain proper order and avoid damage to property.",
      line8: "8. Losses and finds",
      line8_1: "• The hotel is not responsible for lost items.",
      line8_2:
        "• All found items are stored for 3 months before being disposed of.",
      line9: "9. Safety",
        line9_1:
        "• In case of emergency, please contact the administrator immediately or call the appropriate emergency number ( 101 fire service, 102 police, 103 ambulance; these numbers are available around the clock and free of charge to all citizens).",
      line9_2:
        "• The nearest underground shelter is located at 52/54 Vozdvyzhenska St.",
      line9_3: "• Movement restrictions - curfew from 24:00 to 05:00",
    },
  },
};

const ukr = {
  mainPage: {
    mainSec: {
      buttonReserve: "Знайти кімнату",
    },
    aboutHotelSec: {
      title: "Про Готель",
      paragraph:
        "Наш затишний міні готель є частиною готельно-житлового комплексу. Перевагою готелю є унікальне розташування в самому серці історичного Києва - між Контрактовою площею (Поділ) та Софіївською площею (Верхнє місто). Ми дбаємо про безпеку  та щиро піклуємось про комфорт наших гостей. Пропонуємо розміщення в апартаментах трьох різних категорій",
      buttonText: "ДІЗНАТИСЯ БІЛЬШЕ",
    },
    typeRoomSec: {
      buttonText: "ПЕРЕГЛЯНУТИ НОМЕР",
      guest: "гостей",
      bed: "ліжко",
      beds: "ліжка",
      economy: {
        title: "Бюджетний",
        description: "Набір всіх необхідних зручностей для проживання",
      },
      comfort: {
        title: "Комфорт",
        description: "Затишний номер з усіма зручностями",
      },
      lux: {
        title: "Люкс",
        description: "Просторий та комфортний номер для відпочинку.",
      },
    },
    prosSec: {
      locationText: {
        title: "Розташування",
        description: "Прямо в центрі міста",
      },
      kitchenZoneText: {
        title: "Кухонна Зона",
        description: "У всіх номерах є кухонна зона",
      },
      washingText: {
        title: "Автостоянка",
        description: "Охороняєма автостоянка*",
        notice: "*За додаткову плату",
      },
    },
  },
  header: {
    nav: {
      mainText: "Головна",
      roomsText: "Кімнати",
      galleryText: "Галерея",
      rulesText: "Правила",
      contactsText: "Контакти",
    },
    buttonText: "ЗАБРОНЮВАТИ",
  },
  footer: {
    adress: "Гармонія, Київ, вул. Воздвиженська , 60В",
    socialMedia: "Зв'яжіться з нами",
  },
  roomsPage: {
    title: "Номери",
    miniTitleText: "Номер",
    priceSec: {
      fromText: "від",
      detail: "ГРН/ніч",
    },
    detailsSec: {
      guestNumText: "Гостей",
      roomNumText: "Кімната",
      areaText: "м2",
      bedText: "Ліжко",
      bedsText: "Ліжка",
      tvText: "ТВ",
      waterText: "Питна Вода",
    },
    economy: {
      title: "Бюджетний",
      description:
        "БЕЗ ВІКНА, двоспальне ліжко, мікрохвильова піч, електрочайник, міні холодильник, столовий посуд, вентилятор, керамічний обігрівач,телевізор (кабельне телебачення), сейф, рушники, гель для душу, шампунь, фен",
    },
    comfort: {
      title: "Комфорт",
      description:
        "Балкон, двоспальне ліжко, мікрохвильова піч, електрочайник, міні холодильник, столовий посуд, вентилятор, керамічний обігрівач, телевізор (кабельне телебачення), сейф, рушники, гель для душу, шампунь, фен",
    },
    lux: {
      title: "Люкс",
      description:
        "Балкон, двоспальне ліжко (кінг сайз), розкладний диван, мікрохвильова піч, електрочайник, міні холодильник, столовий посуд, вентилятор, керамічний обігрівач, телевізор (кабельне телебачення), сейф, рушники, гель для душу, шампунь, фен",
    },
  },
  galleryPage: {
    title: "Галерея",
    closeButton: "Закрити",
  },
  contactsPage: {
    title: "Контакти",
    contactSec: {
      adress: {
        title: "Адреса",
        adressText: "Гармонія, Київ, вул. Воздвиженська , 60В",
      },
      phone: {
        title: "Телефон",
      },
      email: {
        title: "Пошта",
      },
    },
  },
  rulesPage: {
    title: "Правила",
    rulesText: {
      line1: "1. Час заїзду та виїзду",
      line1_1: "• Час заїзду: з 13:00",
      line1_2: "• Час виїзду: до 11:00",
      line1_3:
        "• Пізній виїзд та ранній заїзд оплачуються додатково за домовленістю з адміністрацією.",

      line2: "2. Заселення",
      line2_1:
        "• Заселення проводиться особисто або, за попередньою домовленістю, з адміністрацією в безконтактному режимі.",
      line2_2:
        "• Для заїзду потрібен дійсний державний документ, що посвідчує особу.",

      line3: "3. Оплата проживання",
      line3_1: "• Повна оплата здійснюється під час заїзду.",
      line3_2:
        "• В якості гарантії для проживання строком більше доби необхідна передоплата в розмірі вартості проживання за одну добу.",
      line3_3:
        "• Прийняті форми оплати: готівковий розрахунок, оплата на банківську карту, безготівковий розрахунок на рахунок підприємства.",
      line3_4:
        "• За попередньою домовленістю готель надає документи для звітності  про відрядження. ",
    
      line4: "4. Політика тиші",
      line4_1:
        "• Час тиші з 22:00 до 08:00, просимо поважати інших гостей та зводити шум до мінімуму.",

      line5: "5. Паління та алкоголь",
      line5_1:
        "• Паління в приміщенні заборонено. Палити дозволено лише в спеціально відведених місцях (балкони).",
      line5_2: "• Вживання алкоголю дозволено в міру, за умови, що це не турбує інших гостей.",
      line6: "6. Домашні тварини",
      line6_1:
        "• За попереднім узгодженням з адміністрацією дозволяється проживання з домашніми тваринами маленьких порід.",
      line7: "7. Порядок у номерах",
      line7_1: "• Гості повинні підтримувати належний порядок та уникати пошкодження майна.",
      line8: "8. Втрати та знахідки",
      line8_1: "• Готель не несе відповідальності за загублені речі.",
      line8_2:
        "• Усі знайдені речі зберігаються протягом 3 місяців до їх утилізації.",
      line9: "9. Безпека",
        line9_1:
        "• У разі надзвичайної ситуації негайно звертайтесь до адміністратора  або  зателефонуйте за відповідним номером екстреної служби (101 пожежна служба, 102 поліція, 103 швидка допомога; ці номери доступні цілодобово та безкоштовно для всіх громадян).",
      line9_2:
        "• Найближче підземне укриття знаходиться за адресою вул. Воздвиженська 52/54.",
      line9_3: "• Обмеження пересування - комендантська година з 24:00 до 05:00.",
    },
  },
};

export { eng, ukr };
